<template>
  <div class="app-container">
    <nav-bar :accountModalOpen="accountModalOpen" @changeAccountModalState="changeAccountModalState" @showAlert="showAlert" @changeSideCartState="changeSideCartState">
    </nav-bar>
    <side-cart :visible="sideCartOpen" @closeSideCart="sideCartOpen = false"></side-cart>
    <v-alert
        v-show="alertShow"
        :type="alertType"
        :title="alertTitle"
        :width="alertWidth"
        class="navalert">
    </v-alert>
    <router-view class="routerView" @showAlert="showAlert" @changeSideCartState="changeSideCartState"/>
    <footer-bot @showAlert="showAlert" style="margin-top:auto; margin-bottom: 1px;"></footer-bot>
  </div>
</template>
<script>
import navBar from './components/NavBar.vue';
import sideCart from './components/SideCart.vue';
import footer from './components/Footer.vue';
import 'animate.css';

export default {
  name: 'App',
  components: {
    "nav-bar" : navBar,
    'side-cart' : sideCart,
    "footer-bot" : footer
  },
  data () {
    return {
      alertTitle: "",
      alertType: "success",
      alertShow: false,
      alertWidth: "300px",
      sideCartOpen: false,
      accountModalOpen: false,
    }
  },
  methods : {
    changeSideCartState: function (state)
    {
      if( this.validSideCartPage) 
      {   
        if( state !== undefined && (state === true || state === false) )
        {
          this.sideCartOpen = state;
        }
        else //if no state was passed then we switch the state to th eopposite of what it is currently.
        {
          this.sideCartOpen = !this.sideCartOpen;
        }
      }
      else
      {
        this.sideCartOpen = false;
      }
    },
    changeAccountModalState: function (state)
    {
      if( state !== undefined && (state === true || state === false) )
      {
        this.accountModalOpen = state;
      }
      else //if no state was passed then we switch the state to th eopposite of what it is currently.
      {
        this.accountModalOpen = !this.accountModalOpen;
      }
    },
    showAlert : function (details) 
    {
      this.alertTitle = details.title;
      this.alertType = details.type;
      this.alertWidth = details.width && details.width !== "" ? details.width : "300px";
      this.alertShow = true;
      setTimeout( function () 
      {
        this.alertShow = false;
        this.alertTitle = "";
        this.alertType = "success";
        this.alertWidth = "300px";
      }.bind(this), details.time);
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  computed : {
    validSideCartPage : function ()
    {
      return this.$store.getters.getValidSideCartScreen;
    }
  },
  mounted () {
    this.$store.dispatch('isUserLoggedIn');
    this.$store.dispatch('isUserMod'); 
    
    //checking for cookie consent cookie, and cookie consent options to enable/disable certain website features.
    const hasConsentCookie = this.getCookie('cookie_consent_user_accepted');
    const consentCookieOptions = this.getCookie('cookie_consent_level');
    if ( hasConsentCookie && consentCookieOptions !== undefined)
    {
      const decodedValue = decodeURIComponent(consentCookieOptions);
      const parsedValue = JSON.parse(decodedValue);

      const isTargetingEnabled = parsedValue.targeting;
      const isTrackingEnabled = parsedValue.targeting;

      if( isTargetingEnabled || isTrackingEnabled )
      {
        this.$store.dispatch("updateCookieConsentState", true);
      }
    }
    else
    {
      this.$store.dispatch("updateCookieConsentState", false);
    }
  },

}
</script>
<style lang="scss">

//fonts import
@font-face {
    font-family: 'Hind';
    font-weight: bold;
    src: url('./assets/fonts/Hind-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Hind-Light';
    font-weight: 300;
    src: url('./assets/fonts/Hind-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Hind-Medium';
    font-weight: 500;
    src: url('./assets/fonts/Hind-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Hind-Bold';
    // font-weight: 500;
    src: url('./assets/fonts/Hind-Bold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
}


#app {
  font-family: new-order, sans-serif, Hind, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $forest;
  background-color: $ivory;
}

.app-container
{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* vuetify alert override */
.v-alert.navalert { 
    position: fixed;
    z-index: 999;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}

.routerView
{
  padding-top:120px;
}

@media (max-width: 730px) {
  .routerView {
    padding-top: 76px;
  }
}

h1
{
  font-family: new-order, sans-serif;
  font-weight: bold;
  font-size: 64px;
}

h2{
  font-family: new-order, sans-serif;
  font-weight: bold;
  font-size: 36px;
}

h3{
  font-family: new-order, sans-serif;
  font-weight: 500;
  font-size: 32px;
}

h4{
  font-family: Hind, new-order, sans-serif;
  font-weight: normal;
  font-size: 20px;
}

h5{
  font-family: Hind, new-order, sans-serif;
  font-weight: normal;
  font-size: 16px;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.h-bold {
  font-weight: bold;
  font-family: 'Hind-Bold';
}
</style>
