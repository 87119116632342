export const freeDeliveryTresholdMixin = {
    computed : {
        progressBarColour: function ()
        {
          if( this.percentageTillFreeDelivery < 100 )
          {
            return "#104112";
          }
          else
          {
            return "#2a882e";
          }
        },
        remainingValueTillFreeDelivery : function ()
        {
          if( this.totalPrice ) 
          {
            if( 80 - this.totalPrice > 0 )
            {
              return (80-this.totalPrice).toFixed(2);
            }
            else
            {
              return 0;
            }
          }
          else
          {
            return null;
          }
        },
        percentageTillFreeDelivery: function ()
        {
          if( this.totalPrice ) 
          {
            if (80 - this.remainingValueTillFreeDelivery < 80)
            {
              return (((80 - this.remainingValueTillFreeDelivery)/80)* 100).toFixed(2);
            }
            else
            {
              return 100;
            }
          }
          else
          {
            return 0;
          }
        },
        totalPrice : function ()
        {
            let total = 0;
            for( let x = 0; x < this.cartItems.length; x++ )
            {
                total = total + (this.cartItems[x].Price * this.cartItems[x].Quantity);
            }
            return total.toFixed(2);
        },
        cartItems : function () 
        {
            return this.$store.getters.getCart;
        },
    }
}